'use client'
import clsx from 'clsx'
import React, { useId } from 'react'
import PhoneInput from 'react-phone-number-input'
import { Value, Country } from 'react-phone-number-input/core'
import 'react-phone-number-input/style.css'
import { useState, useEffect } from 'react'
import { Button } from './Button'

const formClasses =
  'block w-full appearance-none rounded-lg border border-gray-200 bg-white py-[calc(theme(spacing.2)-1px)] px-[calc(theme(spacing.3)-1px)] text-gray-900 placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-600 sm:text-sm'

function Label({ id, children }: { id: string; children: React.ReactNode }) {
  return (
    <label htmlFor={id} className="mb-2 block text-sm font-semibold text-gray-900">
      {children}
    </label>
  )
}

export function TextField({
  label,
  type = 'text',
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & { label?: string }) {
  let id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <input id={id} type={type} {...props} className={formClasses} />
    </div>
  )
}

export function SelectField({
  label,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'select'>, 'id'> & { label?: string }) {
  let id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <select id={id} {...props} className={clsx(formClasses, 'pr-8')} />
    </div>
  )
}

export function Textarea({
  label,
  className,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'textarea'>, 'id'> & {
  label?: string
}) {
  let id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <textarea id={id} {...props} className={clsx(formClasses, 'pr-8')} />
    </div>
  )
}

export function UploadField({
  label,
  className,
  accept,
  description,
  onChange,
  fileSelected,
  error,
  uploadProgress,
  showProgress,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & {
  label?: string
  description?: string
  accept?: string
  fileSelected?: any
  error?: string
  uploadProgress?: number
  showProgress?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
}) {
  let id = useId()
  const [selected, setSelected] = useState(fileSelected)

  return (
    <div className={clsx(className, 'mb-8')}>
      {label && <Label id={id}>{label}</Label>}
      <div className="relative">
        <div
          className="mb-4 h-80 overflow-hidden rounded-lg bg-gray-200"
          style={{ display: selected && !error ? 'block' : 'none' }}
        >
          <video className="mx-auto h-full w-full" id={String(id) + '-video-preview'} controls />
        </div>

        <div
          className="flex w-full items-center justify-center"
          style={{ display: selected && !error ? 'none' : 'block' }}
        >
          <label
            htmlFor="dropzone-file"
            className="flex h-80 w-full cursor-pointer flex-col items-center justify-center rounded-lg border border-dashed border-gray-900/25 bg-gray-100  hover:bg-gray-200"
          >
            <div className="relative flex flex-col items-center justify-center pb-6 pt-5">
              <svg
                className="mb-4 h-8 w-8 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm font-bold text-gray-500">
                <span className="font-semibold">Click to upload</span> or drag and drop
              </p>
              <p className="mb-2 text-xs font-bold text-gray-500 ">{description}</p>
              {error && <p className="text-xs italic text-red-500">{error}</p>}
            </div>
          </label>

          <input
            id="dropzone-file"
            type="file"
            accept={accept}
            className="hidden"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const files = event?.currentTarget?.files
              if (files && files.length > 0) {
                const video: any = document.getElementById(id + '-video-preview')
                const media = URL.createObjectURL(files[0])
                setSelected(files[0])

                if (video) {
                  video.src = media
                  setSelected(files[0])
                }
              }
              if (onChange) onChange(event)
            }}
            {...props}
          />
        </div>
        {selected && !error && uploadProgress == 100 && (
          <>
            {/* <Button
              variant="solid"
              color="gray"
              className="absolute right-2 top-2 mb-2 text-xs"
              onClick={() => {
                setSelected(null)
              }}
            >
              X
            </Button> */}
            <Button
              variant="solid"
              color="gray"
              className="absolute right-1 top-2 mb-2 mr-2 text-xs"
              onClick={() => document.getElementById('dropzone-file')?.click()}
            >
              Change
            </Button>
          </>
        )}
      </div>
      {showProgress && (
        <div
          className="max-h-full w-full"
          style={{ display: uploadProgress && uploadProgress > 0 && selected && !error ? 'block' : 'none' }}
        >
          <div className="relative rounded-lg bg-white ">
            <div className="text-center">
              <div className="mb-4 h-4 rounded-full bg-gray-200">
                <div
                  className="h-full rounded-full bg-blue-800 text-xs"
                  style={{ width: `${uploadProgress}%`, color: 'white' }}
                >
                  {uploadProgress == 100 ? 'Uploaded' : String(uploadProgress) + '% Uploading...'}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export function PhoneField({
  label,
  className,
  value,
  countries,
  country,
  defaultCountry,
  addInternationalOption,
  onChange,
  ...props
}: Omit<React.ComponentPropsWithoutRef<'input'>, 'id'> & {
  label?: string
  value?: string
  countries?: Country[]
  country?: Country
  defaultCountry?: Country
  addInternationalOption?: boolean
  onChange: (value?: Value) => void
}) {
  let id = useId()

  return (
    <div className={className}>
      {label && <Label id={id}>{label}</Label>}
      <PhoneInput
        value={value}
        onChange={onChange}
        countries={countries}
        country={country}
        defaultCountry={defaultCountry}
        addInternationalOption={addInternationalOption}
        id={id}
        {...props}
        numberInputProps={{
          className: clsx(formClasses, 'pr-8'),
        }}
      />
    </div>
  )
}

'use client'

import Link from 'next/link'
import { Popover } from '@headlessui/react'
import { AnimatePresence, motion } from 'framer-motion'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { Button } from '@/components/Button'
import { Container } from '@/components/Container'
import { Logo } from '@/components/Logo'
import { NavLinks } from '@/components/NavLinks'
import { signIn, signOut, useSession } from 'next-auth/react'

function MenuIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path d="M5 6h14M5 18h14M5 12h14" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

function ChevronUpIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-hidden="true" {...props}>
      <path d="M17 14l-5-5-5 5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

function MobileNavLink(
  props: Omit<React.ComponentPropsWithoutRef<typeof Popover.Button<typeof Link>>, 'as' | 'className'>,
) {
  return <Popover.Button as={Link} className="block text-base leading-7 tracking-tight text-gray-700" {...props} />
}

export function Header() {
  const data = useSession()
  const session = data.data
  const status = data.status
  // const { data: session, status } = useSession()
  console.log(data)

  return (
    <header className="sticky top-0 z-50 bg-white">
      <nav>
        <Container className="relative z-50 -mb-4 flex justify-between py-8">
          <div className="relative z-10 flex items-center gap-16">
            <Link href="/" aria-label="Home">
              <Logo className="h-9 w-auto" />
            </Link>
            <div className="hidden scroll-smooth lg:flex lg:gap-10">
              <NavLinks />
            </div>
          </div>
          <div className="flex items-center gap-6">
            <Popover className="lg:hidden">
              {({ open }) => (
                <>
                  <Popover.Button
                    className="relative z-10 -m-2 inline-flex items-center rounded-lg stroke-blue-900 p-2 hover:bg-blue-200/50 hover:stroke-blue-600 active:stroke-blue-900 ui-not-focus-visible:outline-none"
                    aria-label="Toggle site navigation"
                  >
                    {({ open }) => (open ? <ChevronUpIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />)}
                  </Popover.Button>
                  <AnimatePresence initial={false}>
                    {open && (
                      <>
                        <Popover.Overlay
                          static
                          as={motion.div}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          className="fixed inset-0 z-0 bg-gray-300/60 backdrop-blur"
                        />
                        <Popover.Panel
                          static
                          as={motion.div}
                          initial={{ opacity: 0, y: -32 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{
                            opacity: 0,
                            y: -32,
                            transition: { duration: 0.2 },
                          }}
                          className="absolute inset-x-0 top-0 z-0 origin-top rounded-b-2xl bg-gray-50 px-6 pb-6 pt-32 shadow-2xl shadow-gray-900/20"
                        >
                          <div className="space-y-4 scroll-auto">
                            <MobileNavLink href="/#features">Why us?</MobileNavLink>
                            <MobileNavLink href="/#reviews">Reviews</MobileNavLink>

                            <MobileNavLink href="/faqs">FAQs</MobileNavLink>
                            <MobileNavLink href="/articles">Articles</MobileNavLink>
                          </div>
                          <div className="mt-8 flex flex-col gap-4">
                            <Button
                              href="https://airtable.com/apppIjZSJrpUp6QxF/shrNNwoAVbMJGS9K5"
                              target="_blank"
                              variant="outline"
                            >
                              Contractor signup
                            </Button>
                            <Button href="/quote/start">Get a price</Button>

                            {status === 'authenticated' ? (
                              <Button
                                href="#"
                                onClick={() => signOut()}
                                className="block px-3 py-1 text-sm leading-6"
                                variant="outline"
                              >
                                Logout
                              </Button>
                            ) : (
                              <Button href={'/login'} className="block px-3 py-1 text-sm leading-6" variant="outline">
                                Login
                              </Button>
                            )}
                          </div>
                        </Popover.Panel>
                      </>
                    )}
                  </AnimatePresence>
                </>
              )}
            </Popover>
            <Button
              href="https://airtable.com/apppIjZSJrpUp6QxF/shrNNwoAVbMJGS9K5"
              target="_blank"
              variant="outline"
              className="hidden lg:block"
            >
              For Contractors
            </Button>
            <Button href="/quote/start" target="_blank" variant="solid" color="blue" className="hidden lg:block">
              Get a price
            </Button>
            <Menu as="div" className="relative hidden lg:block">
              {status === 'authenticated' ? (
                <>
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">{session?.user?.name}</Menu.Button>
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      <Link href="/profile" className="block px-3 py-1 text-sm leading-6 text-gray-900">
                        Your Profile
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link href="/login?" onClick={() => signOut()} className="block px-3 py-1 text-sm leading-6 ">
                        Sign Out
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </>
              ) : (
                <>
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="hidden lg:flex lg:items-center">
                      <Button href={'/login'} className="block px-3 py-1 text-sm leading-6" variant="outline">
                        Login
                      </Button>
                    </span>
                  </Menu.Button>
                </>
              )}
            </Menu>
          </div>
        </Container>
      </nav>
    </header>
  )
}

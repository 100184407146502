'use client'
import axios from 'axios'
import React, { useState } from 'react'
import { Button } from '@/components/Button'
import { TextField } from '@/components/Fields'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const SubscribeForm = () => {
  const [email, setEmail] = useState('')

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    try {
      const response = await axios.post('/services/subscribe', { email })

      if (response.data.message === 'success') {
        toast.success('Subscribed successfully!')
        setEmail('')
      } else {
        toast.error(response.data.error)
      }
    } catch (error) {
      toast.error('Something went wrong. Please try again a bit later.')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="flex w-full justify-center md:w-auto">
      <TextField
        type="email"
        aria-label="Email address"
        placeholder="Email address"
        autoComplete="email"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-60 min-w-0 shrink"
      />
      <Button type="submit" color="blue" className="ml-4 flex-none">
        <span className="hidden lg:inline">Join our newsletter to get exclusive discounts</span>
        <span className="lg:hidden">Join newsletter</span>
      </Button>
    </form>
  )
}

export default SubscribeForm
